<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        :lazy-validation="lazy"
                        class="w-100 pa-8">
                    <v-container fluid>
                        <dynoform @change="UpdateFormField($event, 'formdata')"
                                  @changeGrid="UpdateExtData($event, $event.tableName)"
                                  @click="FormButtonClicked($event)"
                                  :attributes="GetFormAttributes"
                                  @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                  @HeaderButtonClicked="HeaderButtonClickedDyno($event)"
                                  @changeAttribute="ChangeAttribute($event,'formdata')"
                                  :tabname="tab"
                                  :durum="durum"></dynoform>
                    </v-container>

                    <v-container fluid>
                        <grid :columns="GetExdataColumns('irsaliyeler')"
                              :data="GetExdataItems('irsaliyeler')"
                              :header="$t('TH_ShipmentItems')"
                              @change="UpdateExtData($event, 'irsaliyeler')"
                              @search="Search($event)"
                              @selected="Selected($event)"
                              @rowclicked="RowClicked($event, 'irsaliyeler')"
                              @SearchInputChanged="SearchInputChanged($event, 'irsaliyeler')"
                              @RowButtonClicked="RowButtonClicked($event, 'irsaliyeler')"
                              @AddRow="AddRow2ExtData($event, 'irsaliyeler')"
                              :attributes="GetExtdataAttributes('irsaliyeler')"
                              addrow=""
                              :clickrow="1 == 1" />
                    </v-container>
                </v-form>
            </div>
            <v-dialog v-model="actionBarWait"
                      persistent>
                <v-card color="transparent"
                        dark class="py-5">
                    <v-card-text class="text-center py-5">
                        <v-progress-circular indeterminate
                                             :size="50"
                                             color="white"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="!isgformsdataloaded" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="!isgformsdataloaded" class="primary--text overline">{{ $t("IM_RetrievingData") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-for="(action, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="PageAction(action)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="action.action">
                    {{ $t(action.label) }}
                </v-btn>
            </v-footer>
        </v-main>
    </div>
</template>

<style>
    .v-dialog {
        box-shadow: none !important;
    }
</style>
<script>
    import Vue from 'vue';
    import Vuex from 'vuex';

    Vue.use(Vuex);

    var irsaliyeler = {
        tableName: 'irsaliyeler',
        columns: {
            select:
            {
                type: 'checkbox',
                name: 'select',
                label: 'AL_Select',
                edit: '01',
                width: 10,
                truevalue: 1,
            },
            id: {
                type: 'int',
                name: 'id',
                label: 'FL_FormNo',
                edit: '',
            },
            irsaliyeno: {
                type: 'text',
                name: 'irsaliyeno',
                label: 'FL_WaybillNo',
                edit: '',
            },
            tarih: {
                type: 'date',
                name: 'tarih',
                label: 'FL_CreationTime',
                edit: '',
            },
            duzenlemetarihi: {
                type: 'date',
                name: 'duzenlemetarihi',
                label: 'FL_ArrangementDate',
                edit: '',
            },
            sevktarihi: {
                type: 'date',
                name: 'tarih',
                label: 'FL_ShipmentDate',
                edit: '',
            },
            toplamtutar: {
                type: 'money',
                name: 'tarih',
                label: 'FL_TotalAmount',
                edit: '',
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'GetWaybills'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var dynoFormInfo = {
        tabs: [
            { order: 1, header: 'TH_WaybillSearchCriteria' },

        ],
        TabAttributes: {
            "1": { show: true },
        },
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_Criteria'
            },
            {
                tab: 1,
                order: 2,
                header: 'InvoiceInformation'
            },
        ],

        Columns: {
            id: {
                type: 'int',
                group: 1,
                sira: 20,
                name: 'formid',
                label: 'FL_FormNo',
                edit: '01',
                col: '6',

            },
            irsaliyeno: {
                type: 'text',
                group: 1,
                sira: 25,
                name: 'irsaliyeno',
                label: 'FL_WaybillNo',
                edit: '01',
                col: '6',
            },
            tarihbas: {
                type: 'date',
                group: 1,
                sira: 30,
                name: 'tarihbas',
                label: 'FL_StartDate',
                edit: '01',
                col: '6',
            },
            tarihbit: {
                type: 'date',
                group: 1,
                sira: 35,
                name: 'tarihbit',
                label: 'FL_EndDate',
                edit: '01',
                col: '6',
            },
            ara:
            {
                type: 'button',
                group: 1,
                sira: 90,
                name: 'ara',
                label: 'AL_SearchShip',
                buttontext: 'AL_SearchShip',
                buttoncolor: 'primary',
                edit: '01',
                col: 6,
            },
            gidertipi:
            {
                type: 'select',
                name: 'gidertipi',
                label: 'Fatura Tipi',
                group: 2,
                sira: 10,
                col: '6',
                edit: '01',
                options: [
                    //Filled At Created Method
                ],
            },
            faturano:
            {
                type: 'text',
                name: 'faturano',
                label: 'FL_InvoiceNumber',
                group: 2,
                sira: 20,
                col: 6,
                edit: '01',
            },
     /*       
            duzenlemetarihi:
            {
                type: 'date',
                name: 'duzenlemetarihi',
                label: 'InvoiceDate',
                group: 2,
                sira: 30,
                col: 6,
                edit: '01',
            },
            ek1file:
            {
                type: 'file',
                name: 'ek1file',
                label: 'Fatura Görüntüsü',
                group: 2,
                sira: 50,
                col: 12,
                edit: '01',
            }, */
        },
        form: 'faturaolustur',
        formid: 0,
        formdata: {
            id: 0,
            tarihbas: null,
            tarihbit: null,
            state: '0',
            status: '',
            formid: '',
            ad: '',
        },
        viewName: 'tumu',
        viewparameter: '',

        files: [],
        extdata: [irsaliyeler],
        actions: ['Gönder'],
        parameters: [],
        attributes: {}
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    import Utilities from '@/Utilities.js'

    function ad_AE(payload) {
        /*alert('AE')*/
    }

    export default {
        name: 'faturaolustur',
        mixins: [FormMixIn],
        components: {
            dynoform,
            grid,
            leftmenu,
            kview
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: { type: String, default: 'tumu' },
            drawer: { type: Boolean, default: true },
            single: { type: Boolean, default: false },
        },
        data: function () {
            return {
                valid: false,
                lazy: true,
                viewname: 'tumu',
                form: 'tedarik',
                validateError: '',
                username: '',
                formname: 'irsaliyeler',
                tab: 'Tab-1',
                durum: null,
                isgformsdataloaded: false,
            };
        },
        computed: {
            GetState() {
                return this.$store.getters.GetFormState;
            },
        },
        methods: {
            FormLoaded: function () {
                Utilities.log('FORM LOADED');
                var formdata = this.GetFormData;
            },

            Selected: function ()
            {
                return;
            },
            gidertipi_Field_Changed: function ()
            {
                if (this.GetFormField('gidertipi') != 'E')
                {
                    this.ClearFieldHidden('ek1file');
                }
                else
                {
                    this.SetFieldHidden('ek1file');
                }
            },

            ValidateForm: function (action) {
                this.ClearFieldErrors();
                this.errors = [];
                var result = "";

                var formdata = this.GetFormData;

                var err;
                if (formdata.gidertipi == "") {
                    err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_EInvoice') });
                    this.errors.push(err);
                    this.SetFieldError('FL_EInvoice');
                }
                if (formdata.faturano == "") {
                    err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_InvoiceNumber') });
                    this.errors.push(err);
                    this.SetFieldError('faturano');
                }
                var items = this.GetExdataItems('irsaliyeler');
                if (items.length == 0) {
                    err = this.$root.$i18n.t('EM_NoWaybillSelected');
                    this.errors.push(err);
                    this.Error = true;
                } else {
                    var selectedItems = items.filter(kalem => kalem.select == 1);
                    if (selectedItems.length == 0) {
                        err = this.$root.$i18n.t('EM_NoWaybillSelected');
                        this.errors.push(err);
                        this.Error = true;
                    }
                }

                if (this.errors.length > 0) {
                    result = this.errors;
                }
                return result;
            },
            PageAction: function (action) {
                var messages = {};
                var validateResult = this.ValidateForm();
                if (validateResult && validateResult.length > 0) {
                    messages = {
                        errors: validateResult,
                        infoBody: '',
                        messageType: 'E',
                        viewType: 'notification',
                    };
                    this.$root.$emit('setUpDialog', messages);
                    return;
                }

                var payload = {
                    form: 'faturaolustur',
                    formid: 0,
                    action: action,
                    viewName: ''
                };
                var messages = {};
                this.actionBarWait = true;
                var self = this;
                this.$store
                    .dispatch('SendFormAction', payload)
                    .then(response => {
                        self.actionBarWait = false;

                        if (response.data.errorCode == 0)
                        {
                            messages = {
                                errors: [this.$root.$i18n.t("DM_AskRedirectToForm", { form: this.$root.$i18n.t("FN_Invoice") })],
                                infoBody: '',
                                messageType: 'CB',
                                viewType: 'dialog',
                                OnApprove: function (message)
                                {
                                    self.$router.push({
                                        name: 'faturaform',
                                        params: { id: response.data.infoMessage }
                                    });
                                }
                            };

                            // Grid'i update et
                            let self = this;
                            this.$store.dispatch("GetExtData", 'irsaliyeler')
                                .then(response =>
                                {
                                    Utilities.log("Got Extdata", response);
                                    var axiosCount = self.GetAxiosCount;
                                    if (axiosCount == 0 && typeof self.FormLoaded == "function") self.FormLoaded();
                                })
                                .catch(err =>
                                {
                                    Utilities.log("Hata:", err);
                                    messages = {
                                        errors: [this.$root.$i18n.t('EM_UnexpectedErrorTryAgain')],
                                        infoBody: '',
                                        messageType: 'E',
                                        viewType: 'notification',
                                    };
                                });
                        }
                        else
                        {
                            messages = {
                                errors: [this.$root.$i18n.t(response.data.errorMessage)],
                                infoBody: '',
                                messageType: 'E',
                                viewType: 'notification',
                            };
                        }
                        this.$root.$emit('setUpDialog', messages);


                    })
                    .catch(error => {
                        Utilities.log('FormAction catch error:', error);
                        this.actionBarWait = false;
                        var messages = {
                            errors: [this.$root.$i18n.t('EM_UnexpectedErrorTryAgain')],
                            infoBody: '',
                            messageType: 'E',
                            viewType: 'notification',
                        };
                        this.$root.$emit('setUpDialog', messages);
                    });
            },
            FormButtonClicked(col) {
                Utilities.log("Form button clicked", col)
                if (col.name == 'ara') {
                    let self = this;
                    this.$store.dispatch("GetExtData", 'irsaliyeler')
                        .then(response => {
                            Utilities.log("Got Extdata", response);
                            var axiosCount = self.GetAxiosCount;
                            if (axiosCount == 0 && typeof self.FormLoaded == "function") self.FormLoaded();
                        })
                        .catch(err => {
                            Utilities.log("Hata:", err);
                            var messages = {
                                errors: [this.$root.$i18n.t('EM_UnexpectedErrorTryAgain')],
                                infoBody: '',
                                messageType: 'E',
                                viewType: 'notification',
                            };
                            this.$root.$emit('setUpDialog', messages);
                        });
                }
            },
            FieldButtonClicked(col)
            {
                return;
            },
            RowClicked: function (rowdata, table) {
                Utilities.log('clicked on table:', table, ' Row:', rowdata);
            },
            RowButtonClicked: function (payload, tableName) 
            {
                return;
            },
            HeaderButtonClickedDyno(payload)
            {
                return;
            },
            HeaderButtonClickedasdasd(payload, tableName)
            {
                return;
            },
            ChangeAttribute(e, tableName) {

                var payload = {};
                payload.attributeName = e.attributeName;
                payload.fieldName = e.column.name;
                payload.value = e.value;
                payload.tableName = tableName;
                Utilities.log("FORM Emit changeAttribute", payload)
                this.$store.commit('SetFieldAttributes', payload);
            },

        },

        created: function () {
            var columns = dynoFormInfo.Columns;
            var attributes = {};
            for (var key in columns) {
                if (columns.hasOwnProperty(key)) {
                    attributes[key] = {
                        items: [],
                        readonly: false,
                        hide: '',
                        error: false,
                        disabled: false,
                        approved: true
                    };
                }
            }
            dynoFormInfo.attributes = attributes;


            dynoFormInfo.Columns.gidertipi.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: this.$root.$i18n.t('E-FATURA'), value: 'E' },
                { text: this.$root.$i18n.t('Fatura'), value: 'F' },
                { text: this.$root.$i18n.t('Gider Pusulası'), value: 'G' },
                { text: this.$root.$i18n.t('SMM'), value: 'S' },
                { text: this.$root.$i18n.t('Diğer'), value: 'D' }
            ];
        },
        mounted: function () {
            this.$store.commit('updateFormInfo', dynoFormInfo);
            this.$store.commit('SetFormData', dynoFormInfo.formdata);
            this.$store.commit('SetFormActions', [{
                label: 'AL_CreateInvoice',
                action: 'createInvoice'
            }]);
            this.isgformsdataloaded = true;

            //this.SetFieldHidden('ek1file');
        },
    };
</script>
